<template>
  <div class="view pa24">
    <div class="d-flex align-items-center">
      <div class="mr20 mb10">
        姓名：
        <el-input class="w220" v-model="nickeName" placeholder="请输入用户/员工姓名" />
      </div>
      <div class="mr20 mb10">
        手机号：
        <el-input class="w220" v-model="phone" placeholder="请输入手机号" />
      </div>
      <div class="mr20 mb10">
        提现时间：
        <el-date-picker
          v-model="pickerValue"
          value-format="yyyy-MM-dd"
          align="right"
          type="daterange"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
        />
      </div>
    </div>
    <div>
      订单状态：
      <el-radio
        class="mr10"
        style="margin-left: 0"
        v-for="(item, index) in radioArr"
        :key="item"
        v-model="radio1"
        :label="index"
        border
        >{{ item }}</el-radio
      >
      <el-button type="primary" @click="getTableData">搜索</el-button>
    </div>
    <commonTable :tableData="tableData"
                  :loading="loading"
                  @handleSizeChange="handleSizeChange"
                  @handleCurrentChange="handleCurrentChange"
                  :currentPage="currentPage"
                  :total="total">
      <template v-slot:table>
        <el-table-column prop="avatar" align="center" label="头像">
          <template slot-scope="scope">
            <el-image style="width: 36px;height:36px" :src="scope.row.logo ? scope.row.logo : scope.row.avatarUrl">
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="name" align="center" label="姓名">
          <template slot-scope="scope">
            <div  >{{scope.row.name ? scope.row.name : scope.row.nickeName}}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="phone"  label="联系方式" >
          <template slot-scope="scope">
            <div  >{{scope.row.bcPhone ? scope.row.bcPhone : scope.row.phone}}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="amount"
          align="center"
          label="提现金额"
        >
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" :content="getAmountDetail(scope.row)" placement="top-start">
              <div>{{scope.row.realAmount| formatAmount}}</div>
            </el-tooltip>
          </template>
        </el-table-column>
<!--        <el-table-column align="center" prop="userType"  label="用户类别">-->
<!--          <template slot-scope="scope">-->
<!--            <div >{{scope.row.userType == 1 ? "员工" : "普通"}}-->
<!--            </div>-->
<!--          </template>-->
<!--        </el-table-column>-->
        <el-table-column align="center" prop="approvalStatus"  label="状态">
          <template slot-scope="scope">
            <!--审核状态 0等待打款  打款成功1 已驳回2-->
            <el-tooltip class="item" effect="dark" :content="'预计打款时间:'+scope.row.willPayTime" placement="top-start" v-if="scope.row.approvalStatus===0">
              <div>{{scope.row.approvalStatus| withdrawStatusFilter}}</div>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" :content="scope.row.note" placement="top-start" v-else-if="scope.row.approvalStatus===4||scope.row.approvalStatus===2">
              <div>{{scope.row.approvalStatus| withdrawStatusFilter}}</div>
            </el-tooltip>
            <div v-else>
              {{scope.row.approvalStatus| withdrawStatusFilter}}
            </div>

          </template>
        </el-table-column>
        <el-table-column
            prop="createdTime"
            align="center"
            label="申请时间"
        />
        <el-table-column prop="approvalTime" align="center" label="审核时间" />
        <el-table-column prop="payTime" align="center" label="打款时间" />
        <el-table-column align="center" fixed="right" label="操作"  width="170">
          <template slot-scope="scope">
            <el-button v-if="scope.row.approvalStatus === 3" type="text" @click="makeMoney(scope.row.recordId,1)" size="small">
              打款
            </el-button>
            <el-button v-if="scope.row.approvalStatus === 3" type="text" @click="makeMoneyFail(scope.row,2)" size="small">
              驳回
            </el-button>
          </template>
        </el-table-column>
      </template>
    </commonTable>

    <el-dialog title="驳回原因" :visible.sync="makeMoneyFailDialogVisible" width="30%" :append-to-body='true'>
      <el-form :model="failForm">
        <el-form-item>
          <el-input type="textarea" v-model="failForm.remark" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="makeMoneyFailDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="makeMoney(failForm.withdrawalId,failForm.approvalStatus,failForm.remark)">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import { queryWithdrawals,makeMoney } from "@/api/financing";
import {withdrawStatusMap} from "@/utils/constant";
import comm from "@/mixins/comm";
import  {formatAmount} from "@/utils/utils";

export default {
  name: "withdrawalList",
  mixins: [comm],
  components: {
    commonTable,
  },
  filters:{
     withdrawStatusFilter(status){
       return withdrawStatusMap[status]
     }
  },
  data() {
    return {
      makeMoneyFailDialogVisible:false,
      pickerValue: "", //选择时间
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now();
        }
      },
      radioArr: ["全部", "等待打款", "打款成功", "已驳回"],
      roleArr: ["全部", "员工", "用户"],
      radio1: 0,
      radio2:'',
      tableData: [],
      pageSize:10,
      pageNum:1,
      phone:'',
      total:0,
      loading:false,
      currentPage:0,
      nickeName:'',
      failForm:{
        remark:'',
        withdrawalId:null,
        approvalStatus:null,
      }
    };
  },
  created(){
    this.getTableData()
  },
  methods:{
    getAmountDetail(row){
      return "申请金额:"+formatAmount(row.amount)+",手续费:"+formatAmount(row.serviceCharge)
    },
    //请求表格数据
    getTableData() {
      this.loading = true;
      let data = {
        pageSize : this.pageSize,
        pageNum : this.pageNum,
        createdTime:this.pickerValue,
        startTime:this.pickerValue ? this.pickerValue[0]:'',
        endTime:this.pickerValue ? this.pickerValue[1]:'',
        phone:this.phone,
        approvalStatus:this.radio1-1 == '-1' ? '' : this.radio1 -1,
        nickeName : this.nickeName,
        userType:this.radio2 == 0 ? '' : this.radio2
       };
      queryWithdrawals(data)
      .then((res) => {
        let arr = []
        this.loading = false;
        this.tableData = res.data.pageInfo.list;
        this.total = res.data.pageInfo.total;
      })
      .catch((err) => {
        this.loading = false;
        this.tableData = [];
      });
    },
    makeMoneyFail(row,approvalStatus){
      this.failForm.approvalStatus = approvalStatus
      this.failForm.withdrawalId = row.recordId
      this.makeMoneyFailDialogVisible = true
    },
    //打款
    makeMoney(id,approvalStatus,remark){
      this.$confirm('确认'+(approvalStatus == 1 ? "打款" : '驳回')+'吗？', "提示", {
        onConfirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info ",
      })
      .then(() => {
        makeMoney({withdrawalId:id,approvalStatus:approvalStatus,remark:remark})
        .then(res=>{
          if(res.code == 200){
            this.$message({
              type: "success",
              message: "操作成功！",
            });
            this.makeMoneyFailDialogVisible = false
            this.getTableData()
          }
        })
      })
      .catch((err) => {});


    },
    //当前页变更
    handleCurrentChange(num) {
      this.pageNum = num;
      this.getTableData();
    },
    //当前页查询数据条数变更
    handleSizeChange(num) {
      this.pageSize = num;
      this.pageNum = 1;
      this.currentPage = 1;
      this.getTableData();
    },
    detail(id){
      this.$router.push({ path:`/finance/commissionDetail?id=${id}` });
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
